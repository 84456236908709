import React, { useContext } from 'react';
import { AppContext } from '../../contexts-api/AppContextProvider';
import { getAuth } from 'firebase/auth';

import './index.css';

const handleButtonClick1 = async () => {
  // URL du web service
  const apiUrl = 'http://127.0.0.1:5001/dev-skyrenov-edl/europe-west3/api/user';
  //const token = await getAuth().currentUser.getIdToken(true);
  // Données à envoyer
  //const token = await getAuth().currentUser.getIdToken(true);
  // Appel au web service via fetch avec les options spécifiées
  fetch(apiUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Réponse réseau non ok');
      }
      return response.json(); // Convertit la réponse en JSON
    })
    .then((data) => {
      console.log('Réponse reçue:', data);
      // Traitez ici les données reçues si nécessaire
    })
    .catch((error) => {
      console.error('Erreur lors de l’appel API:', error);
      // Gérez ici les erreurs si nécessaire
    });
};

const handleButtonClick2 = async () => {
  // URL du web service
  const apiUrl = 'http://127.0.0.1:5001/dev-skyrenov-edl/europe-west3/api/user';
  const token = await getAuth().currentUser.getIdToken(true);
  // Données à envoyer
  const data = {
    token,
    nameCompany: 'X_SKYRENOV',
    profil: 'administrator',
    name: 'kambiz',
    surname: 'YAZDINEJAD',
    phoneNumber: '+3361640s5922',
    mailAddress: 'jyazdinejad4@lol.fr',
  };

  // Options de la requête fetch
  const options = {
    method: 'POST', // Méthode HTTP
    headers: {
      'Content-Type': 'application/json', // Type de contenu de la requête
      // Ajoutez d'autres en-têtes ici si nécessaire
    },
    body: JSON.stringify(data), // Corps de la requête avec les données converties en chaîne JSON
  };

  // Appel au web service via fetch avec les options spécifiées
  fetch(apiUrl, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Réponse réseau non ok');
      }
      return response.json(); // Convertit la réponse en JSON
    })
    .then((data) => {
      console.log('Réponse reçue:', data);
      // Traitez ici les données reçues si nécessaire
    })
    .catch((error) => {
      console.error('Erreur lors de l’appel API:', error);
      // Gérez ici les erreurs si nécessaire
    });
};

const App = function (props) {
  const { profil } = useContext(AppContext);
  console.log(profil);
  return (
    <>
      <button onClick={handleButtonClick1}>list USERS</button>

      <button onClick={handleButtonClick2}>Create USER</button>
    </>
  );
};

export default App;
