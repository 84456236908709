// import de firebase
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
export const appFirebase = initializeApp(firebaseConfig);
export const dbFirebase = getFirestore(appFirebase);
export const storage = getStorage();

//AJOUT POUR UTILISATION DE L'EMULATEUR
if (process.env.REACT_APP_ENV === 'LOCAL') {
  connectFirestoreEmulator(dbFirebase, 'localhost', 8080);
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
  connectStorageEmulator(storage, '127.0.0.1', 9199);
}
