import React, { useEffect, useContext, useState, useRef } from 'react';

import dayjs from 'dayjs';
import { doc, collection, collectionGroup, onSnapshot, query, where, getDoc } from 'firebase/firestore';
import { dbFirebase, storage } from '../../config';
import { ref, getDownloadURL } from 'firebase/storage';

import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { AppContext } from '../../contexts-api/AppContextProvider';
import { Typography } from '@mui/material';
import {
  waitingEdl,
  createEdl,
  deleteEdl,
  modifyEdl,
  acceptRefuseEdl,
  acceptRefuseReportEdl,
  refuseEdlByAdmin,
} from '../../api-service-customer';
import ViewItem from './ViewItem';

import DialogSky from '../components/DialogSky';
import ViewEDL from '../components/ViewEDL';

import ViewCalendar from './ViewCalendar';
import getDefaultValues from '../newEDL/defaultValue';

import SelectSocietyAdmin from './SelectSocietyAdmin';
import SelectProviderAdmin from './SelectProviderAdmin';
import SelectBookingProvider from './SelectBookingProvider';
import DialogRefuse from './DialogRefuse';
import DialogFinishEDL from './DialogFinishEDL';
import DialogDeleteAssignEdlCustomer from './DialogDeleteAssignEdlCustomer';
import { cloneDeep } from 'lodash';

const _getPrivateInfosAdmin = async (docRef) => {
  const pathRef = docRef.path + '/privateA/refusByProviders';
  const infos = await getDoc(doc(dbFirebase, pathRef));
  return infos.exists() ? infos.data() : null;
};

const _getPricing = async (listPricing, docRef) => {
  const results = {};
  const promises = listPricing.map(async (pathPrivate) => {
    const pathRef = docRef.path + '/' + pathPrivate + '/pricing';
    console.log(pathRef);
    const docPricing = await getDoc(doc(dbFirebase, pathRef));
    if (pathPrivate === 'privateC') results['customer'] = docPricing.data();
    if (pathPrivate === 'privateP' && docPricing.exists()) results['provider'] = docPricing.data();
  });
  await Promise.all(promises); // Attendre que toutes les requêtes soient terminées
  return results;
};

function listenEdls(nameCompany, allEdls, setCountEdls, setMessageInfos, profil) {
  let q = null;
  const typeCompany = profil.company.type;

  const status = ['submit', 'waiting', 'assign', 'control', 'finish'];
  if (['customer', 'admin'].includes(typeCompany)) {
    if (profil.user.profil !== 'administrator') {
      q = query(
        collection(dbFirebase, 'edls', nameCompany, 'listEdls'),
        where('metadatas.status', 'in', status),
        where('customer.user.uid', '==', profil.user.uid),
      );
    } else {
      q = query(collection(dbFirebase, 'edls', nameCompany, 'listEdls'), where('metadatas.status', 'in', status));
    }
  } else if (['provider'].includes(typeCompany)) {
    if (profil.user.profil !== 'administrator') {
      q = query(collectionGroup(dbFirebase, 'privateP'), where('uid', '==', profil.user.uid));
    } else {
      q = query(collectionGroup(dbFirebase, 'privateP'), where('company', '==', profil.company.name));
    }
  }

  const sub = onSnapshot(
    q,
    async (snapshot) => {
      let changeType = null;
      let edls = [];
      const promises = [];

      for (const change of snapshot.docChanges()) {
        const promise = (async () => {
          const changeType = change.type;
          let idEdl,
            datas,
            docRef = null;
          if (typeCompany === 'provider') {
            if (changeType !== 'removed') {
              const docRefEdl = doc(dbFirebase, change.doc.ref.parent.parent.path);
              const docEdl = await getDoc(docRefEdl);
              datas = docEdl.data();
              docRef = docEdl.ref;
              idEdl = docEdl.id;
            } else idEdl = change.doc.ref.parent.parent.id;
          }
          edls.push({
            changeType: changeType,
            idEdl: idEdl ?? change.doc.id,
            datas: datas ?? change.doc.data(),
            docRef: docRef ?? change.doc.ref,
          });
        })();
        promises.push(promise);
      }

      await Promise.all(promises);

      for (const { changeType, idEdl, datas, docRef } of edls) {
        const newEdl = { [idEdl]: datas };
        if (changeType === 'added' || changeType === 'modified') {
          //START FETCH PRICING
          const listPricing = [];
          if (typeCompany === 'customer') listPricing.push('privateC');
          if (typeCompany === 'admin') listPricing.push('privateC', 'privateP');
          if (typeCompany === 'provider') listPricing.push('privateP');
          const pricingUser = await _getPricing(listPricing, docRef);
          Object.keys(pricingUser).forEach((typeCompany) => {
            newEdl[idEdl][typeCompany] = {
              ...newEdl[idEdl][typeCompany],
              pricing: pricingUser[typeCompany],
            };
          });
          //END FETCH PRICING

          //START FETCH REFUS BY PROVIDER
          let privateAdmin = null;
          if (typeCompany === 'admin') privateAdmin = await _getPrivateInfosAdmin(docRef);
          if (privateAdmin !== null)
            newEdl[idEdl] = {
              ...newEdl[idEdl],
              admin: { refusByProviders: privateAdmin },
            };
          //END FETCH REFUS BY PROVIDER

          if (['customer', 'provider'].includes(typeCompany)) {
            if (newEdl[idEdl].metadatas.status === 'waiting') newEdl[idEdl].metadatas.status = 'submit';
            if (typeCompany === 'customer' && newEdl[idEdl].metadatas.status === 'control')
              newEdl[idEdl].metadatas.status = 'assign';
          }

          //Reserialisation de la date
          newEdl[idEdl].manage.dateEDL = dayjs(newEdl[idEdl].manage.dateEDL.seconds * 1000).toDate();
          allEdls.current = {
            ...allEdls.current,
            ...newEdl,
          };
        } else delete allEdls.current[idEdl];
      }

      setCountEdls((prevCount) => {
        return prevCount + 1;
      });
      setMessageInfos((prevMessage) => {
        const newCount = prevMessage[0] + 1;
        if (changeType === 'added') return [newCount, 'success', 'Nouvel EDL Soumis'];
        if (changeType === 'modified') return [newCount, 'warning', 'un EDL a été modifié'];
        if (changeType === 'removed') return [newCount, 'error', 'un EDL a été supprimé'];
        return [newCount, 'error', 'inconnu'];
      });
    },
    (error) => {
      console.log(error);
    },
  );

  return sub;
}

const MyEDLs = ({ setEdlToModify, display, setMessageInfos }) => {
  console.log("gerer changement d'annexes avec affichage modification tarification");
  //GESTION DES ETATS
  //Lorsque l'API google maps est chargé, le render est rappelé à cause du useContext
  const { profil } = useContext(AppContext);

  const allEdls = useRef([]);
  const edl = useRef({});
  const [countEdls, setCountEdls] = useState(0);
  const [dialogEdl, setDialogEdl] = useState(false);
  const [status, setStatus] = useState(undefined);
  const [typeView, setTypeView] = useState('calendar');
  const [nameCompany, setNameCompany] = useState(profil.company.name);
  const [provider, setProvider] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [acceptedBookingProvider, setAcceptedBookingProvider] = useState(false);
  const [refusedUser, setRefusedUser] = useState(false);
  const [finishProvider, setFinishProvider] = useState(false);
  const [dialogCustomerRefuseAfterAssign, setdialogCustomerRefuseAfterAssign] = useState(false);

  // Utilisation de useEffect pour mettre à jour myEdls
  useEffect(() => {
    if (profil && nameCompany !== 'SKY RENOV') {
      allEdls.current = [];
      const unsub = listenEdls(nameCompany, allEdls, setCountEdls, setMessageInfos, profil);
      return unsub;
    }
  }, [profil, nameCompany, setMessageInfos]);

  //GESTION DES HANDLES
  const handleOnClick = ({ edl: _edl }) => {
    // Recherche de l'élément du DOM avec la classe spécifique
    const element = document.querySelector('.fc-popover');
    if (element) element.style.zIndex = '100';
    edl.current = _edl;
    setDialogEdl(true);
    if (edl.current?.metadatas?.status === 'finish') {
      setStatus('finishEdl');
      return;
    }
    switch (profil.company.type) {
      case 'customer':
        if (edl.current?.metadatas?.status === 'submit') {
          setStatus(edl.current?.metadatas?.isModifying ? 'modifiedEdlFromMyEdls' : 'submitEdl');
        }
        if (edl.current?.metadatas?.status === 'assign') {
          edl.current?.metadatas?.isReportUpload
            ? setStatus('controlingEdlCustomer')
            : setStatus('consultAssignEdlCustomer');
        }
        break;
      case 'admin':
        if (['submit', 'waiting', 'assign'].includes(edl.current?.metadatas?.status)) {
          setStatus('assignProvider');
        }
        if (edl.current?.metadatas?.status === 'control') {
          setStatus('controlEdlProvider');
        }
        break;
      case 'provider':
        if (edl.current?.metadatas?.status === 'submit') {
          setStatus('acceptRefuseProvider');
        }
        if (edl.current?.metadatas?.status === 'assign') {
          setStatus('acceptedEdlProvider');
        }
        if (edl.current?.metadatas?.status === 'control') {
          setStatus('waitReportEdlValidation');
        }
        break;
      default:
        // Handle default case here
        break;
    }
  };

  const handleBtnDialog = async ({ btn, motif, newPossession }) => {
    console.log(btn);
    if (btn?.type === 'click') return;

    if (['cancelSubmitEdl', 'cancelModifiedEdl', 'cancelAssignProvider', 'cancelEdlProvider'].includes(btn)) {
      setDialogEdl(false);
      return;
    }

    if (btn === 'canceListProviders') {
      setProvider(false);
      return;
    }
    if (btn === 'cancelBookingProvider') {
      setAcceptedBookingProvider(false);
      return;
    }

    if (btn === 'cancelRefused') {
      setRefusedUser(false);
      return;
    }

    if (btn === 'cancelFinishEdl') {
      setFinishProvider(false);
      return;
    }

    if (['modifiedSubmitEdl', 'modifiedModifiedEdl'].includes(btn)) {
      setIsLoading(true);
      const newEdl = cloneDeep(edl.current);
      console.log(newEdl);
      newEdl.metadatas.isModifying = true;
      delete newEdl.provider;
      setEdlToModify(newEdl);
      if (btn === 'modifiedSubmitEdl') modifyEdl(newEdl.metadatas.idEdl, profil.company.name);
      setDialogEdl(false);
      setIsLoading(false);
      return;
    }

    if (['deleteSubmitEdl', 'deleteModifiedEdl'].includes(btn)) {
      setDialogEdl(false);
      deleteEdl(edl.current.metadatas.idEdl, profil.company.name);
      return;
    }

    if (btn === 'deleteModifiedEdlAssign') {
      setdialogCustomerRefuseAfterAssign(true);
      return;
    }

    if (btn === 'cancelDeleteEdlAssignCustomer') {
      setdialogCustomerRefuseAfterAssign(false);
      return;
    }

    if (btn === 'confirmDeleteEdlAssignCustomer') {
      setDialogEdl(false);
      setdialogCustomerRefuseAfterAssign(false);
      deleteEdl(edl.current.metadatas.idEdl, profil.company.name);
      return;
    }

    if (btn === 'acceptModifiedEdl') {
      setDialogEdl(false);
      const newEdl = cloneDeep(edl.current);
      const pricingCustomer = newEdl.customer.pricing;
      const idEdl = newEdl.metadatas.idEdl;
      delete newEdl.customer;
      delete newEdl.metadatas;
      createEdl(idEdl, newEdl, profil.company.name, pricingCustomer);
      return;
    }

    if (btn === 'showListProvider') {
      setProvider(true);
      setIsLoading(true);
      return;
    }

    if (btn === 'assignlistProviders') {
      setDialogEdl(false);
      setProvider(false);
      waitingEdl(
        edl.current.metadatas.idEdl,
        edl.current.customer.company.name,
        provider.user.uid,
        provider.user.company,
      );
      return;
    }

    if (['acceptEdlProvider', 'modifiedBookingProvider'].includes(btn)) {
      setAcceptedBookingProvider(true);
      return;
    }

    if (btn === 'refuseEdlCustomer') {
      setRefusedUser('refuseMotifEdlCustomer');
      return;
    }

    if (btn === 'refuseEdlProvider') {
      setRefusedUser('refuseBookingProvider');
      return;
    }

    if (btn === 'cancelEdlProvider') {
      setRefusedUser(false);
    }

    if (['acceptBookingProvider', 'refuseBookingProvider'].includes(btn)) {
      setRefusedUser(false);
      setAcceptedBookingProvider(false);
      setDialogEdl(false);
      acceptRefuseEdl(
        btn.startsWith('accept') ? 'accept' : 'refuse',
        edl.current.metadatas.idEdl,
        edl.current.customer.company.name,
        edl.current.manage.dateEDL,
        profil.user.uid,
        profil.user.company,
        edl.current.provider.pricing,
        btn.startsWith('refuse') ? motif : null,
      );
      return;
    }

    if (btn === 'refuseMotifEdlCustomer') {
      setRefusedUser(false);
      setDialogEdl(false);
      refuseEdlByAdmin(edl.current.metadatas.idEdl, edl.current.customer.company.name, motif);
      return;
    }

    if (['finishEdlProvider', 'modifyReportEdlProvider'].includes(btn)) {
      setFinishProvider(true);
      return;
    }

    if (btn === 'uploadFinishEdl') {
      setFinishProvider(false);
      setDialogEdl(false);
      return;
    }

    if (btn === 'viewReportEdlProvider') {
      let fileURL = null;
      if (process.env.REACT_APP_ENV === 'LOCAL') {
        fileURL = edl.current.metadatas.mediaLink;
      } else {
        const storageRef = ref(storage, edl.current.metadatas.pathReportEdl);
        await getDownloadURL(storageRef).then((downloadURL) => {
          fileURL = downloadURL;
        });
      }
      window.open(fileURL, '_blank');
      return;
    }

    if (btn === 'refuseReportEdlProvider') {
      setRefusedUser('motifReportEdlProvider');
      return;
    }

    if (['acceptReportEdlProvider', 'motifReportEdlProvider'].includes(btn)) {
      setFinishProvider(false);
      setDialogEdl(false);
      acceptRefuseReportEdl(
        btn.startsWith('accept') ? 'accept' : 'refuse',
        edl.current.metadatas.idEdl,
        edl.current.customer.company.name,
      );
    }
  };

  const handleNewEdl = (date) => {
    const newEdl = getDefaultValues();
    newEdl.manage.dateEDL = date;
    setEdlToModify(newEdl);
  };

  return (
    <Grid container flexGrow={1}>
      {profil.company.type === 'admin' && profil.user.profil === 'administrator' && (
        <Grid sx={{ padding: 1 }} xs={12}>
          <SelectSocietyAdmin setNameCompany={setNameCompany} />
        </Grid>
      )}
      {countEdls === 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="body">Chargement des données...</Typography>
          <br />
          <CircularProgress />
        </Box>
      )}

      {profil !== null && countEdls > 0 && typeView === 'items' && (
        <Grid flexGrow={1}>
          <ViewItem
            edls={allEdls.current}
            countEdls={countEdls}
            handleOnClick={handleOnClick}
            typeView={typeView}
            setTypeView={setTypeView}
            display={display}
          />
        </Grid>
      )}
      {profil !== null && countEdls > 0 && typeView === 'calendar' && (
        <Grid flexGrow={1}>
          <ViewCalendar
            edls={allEdls.current}
            countEdls={countEdls}
            handleOnClick={handleOnClick}
            typeView={typeView}
            setTypeView={setTypeView}
            handleNewEdl={handleNewEdl}
            typeCompany={profil.company.type}
          />
        </Grid>
      )}

      {dialogEdl === true ? (
        <DialogSky
          open={true}
          handleDialog={handleBtnDialog}
          status={status}
          isLoading={isLoading}
          datasEdl={edl.current}
        >
          <ViewEDL datasEdl={edl.current} profil={profil} display={display} />
        </DialogSky>
      ) : null}

      {provider ? (
        <DialogSky open={true} handleDialog={handleBtnDialog} status="listProviders" isLoading={isLoading}>
          <SelectProviderAdmin datasEdl={edl.current} setProvider={setProvider} setIsLoading={setIsLoading} />
        </DialogSky>
      ) : null}
      {acceptedBookingProvider ? <SelectBookingProvider datasEdl={edl.current} handleDialog={handleBtnDialog} /> : null}
      {refusedUser !== false ? <DialogRefuse status={refusedUser} handleDialog={handleBtnDialog} /> : null}
      {finishProvider ? (
        <DialogFinishEDL datasEdl={edl.current} handleDialog={handleBtnDialog} priceList={profil.priceList} />
      ) : null}

      {dialogCustomerRefuseAfterAssign ? (
        <DialogDeleteAssignEdlCustomer
          datasEdl={edl.current}
          handleDialog={handleBtnDialog}
          pricePenalty={profil.priceList.annulation}
        />
      ) : null}
    </Grid>
  );
};

export default MyEDLs;
